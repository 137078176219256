<template>
  <div class="inspectionRecords-home">
    <div class="topHeader">
      <div class="queryItem">
        <span class="queryItemTitle">检查时间：</span>
        <el-checkbox-group v-model="queryExamDate" @change="changeQueryExamDate">
          <el-checkbox v-for="(item, index) in queryExamDateList" :key="index" :label="item.value">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="queryItem">
        <span class="queryItemTitle">检查类型：</span>
        <el-checkbox-group v-model="queryExamType" @change="changeQueryExamType">
          <el-checkbox v-for="(item, index) in queryExamTypeList" :key="index" :label="item.value">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="queryItem">
        <span class="queryItemTitle">刷选规则：</span>
        <el-checkbox-group style="display: flex" v-model="selectRules" @change="changeSelectRules">
          <el-checkbox label="0">显示全部</el-checkbox>
          <el-checkbox label="1">仅显示差异项</el-checkbox>
          <el-checkbox label="2">仅显示存在异常的项目</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="inspectionRecords-Box" v-loading="loading">
      <table class="inspectionRecords-table">
        <thead>
          <tr>
            <th style="z-index: 99"></th>
            <th v-for="(item, index) in queryDataExamList" :key="index">
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in queryReferenceExamList" :key="index">
            <td>
              <div class="leftTitleContent">
                <div class="leftItemTitle">
                  {{ item.label }}
                </div>
                <div class="leftEleTitle">
                  <div class="leftEleTitleItem" @click="handleExamineTitle(item)">
                    <img class="foldLineImg" :src="foldLineImg" alt="" />
                  </div>
                </div>
              </div>
            </td>
            <td v-for="(ele, idx) in queryExamList[item.label]" :key="idx">
              {{ ele.itemValue }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-dialog width="50%" title="变化趋势" class="add-form" :visible.sync="examineInnerVisible">
      <el-card
        shadow="never"
        class="chartInfoOpen"
        :body-style="{ padding: '0.625rem 0.9375rem 0' }"
        v-if="examRecordTrendList?.length !== 0"
      >
        <div class="lineChart" id="lineChart"></div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'inspectionRecords',
  data() {
    return {
      loading: false,
      examineInnerVisible: false,
      queryExamDate: [],
      queryExamDateList: [],
      queryExamType: [],
      queryExamTypeList: [],
      examType: null,
      queryExamList: null,
      queryDataExamList: null,
      queryReferenceExamList: null,
      selectRules: ['0'],
      examRecordTrendList: [],
      foldLineImg: require('@/assets/img/patientCenter/foldLine.png'),
      patientIndex: null
    }
  },
  created() {
    this.patientIndex = sessionStorage.getItem('patientIndex')
  },
  mounted() {
    this.getQueryInfo()
  },
  methods: {
    // 检查检验
    changeQueryExamDate() {
      if (this.queryExamDate && this.queryExamDate.length > 0) {
        if (this.queryExamDate.length > 1) {
          this.examDate = this.queryExamDate.join(',')
        } else {
          this.examDate = this.queryExamDate[0]
        }
      } else {
        this.examDate = null
      }
      this.queryInfo()
    },
    changeQueryExamType() {
      if (this.queryExamType && this.queryExamType.length > 0) {
        if (this.queryExamType.length > 1) {
          this.examType = this.queryExamType.join(',')
        } else {
          this.examType = this.queryExamType[0]
        }
      } else {
        this.examType = null
      }
      this.queryInfo()
    },
    changeSelectRules() {
      if (this.selectRules && this.selectRules.length > 1) {
        this.selectRules = this.selectRules.slice(-1)
        this.queryRules = this.selectRules[0]
        this.queryInfo()
      } else {
        this.selectRules = [...this.queryRules]
      }
    },
    handleExamineTitle(val) {
      for (let i in this.queryExamList) {
        if (i === val.label) {
          this.examRecordTrendList = this.queryExamList[i]
          this.examineInnerVisible = true
          this.$nextTick(() => {
            this.draw_examLine()
          })
        }
      }
    },
    draw_examLine() {
      let examRecordTrendList = this.examRecordTrendList
      var lineCharts = document.getElementById('lineChart')
      //循环元素
      let xAxisData = []
      let yAxisData = []
      let title = ''
      let valueLength = null
      let decimal_point_index = 1
      examRecordTrendList.forEach((item) => {
        if (item.itemValue) {
          xAxisData.push(item.examineDate)
          yAxisData.push(item.itemValue)
          if (!title) {
            title = item.itemTitle
            valueLength = item.itemValue
          }
        }
      })
      if (valueLength > 1) {
        decimal_point_index = 1
      } else {
        decimal_point_index = 1 / 10 ** valueLength.toString().split('.').pop().length
      }
      if (this.lineChart != null && this.lineChart != '' && this.lineChart != undefined) {
        this.lineChart.dispose() // 销毁
      }
      this.lineChart = echarts.init(lineCharts) // 绘制图表
      var Option = {
        title: {
          text: title
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value',
          minInterval: decimal_point_index
        },
        series: [
          {
            data: yAxisData,
            type: 'line'
          }
        ]
      }
      this.lineChart.setOption(Option)
    },
    arrayTransfer() {
      let arr = []
      let referenceArr = []
      let count = 0
      let examineReferences = new Set()
      for (let i in this.queryExamList) {
        this.queryExamList[i].forEach((ele, index) => {
          if (!ele.itemValue && !ele.uuid) {
            ele.uuid = index + Math.random()
          }
          if (count + 1 <= this.queryExamList[i].length) {
            count++
            arr.push(ele.examineDate + '(' + ele.examineType + ')')
          }
          if (ele.reference && !examineReferences.has(i)) {
            examineReferences.add(i)
            referenceArr.push({
              label: i,
              reference: ele.reference,
              itemUnit: ele.itemUnit
            })
          }
        })
      }
      this.queryDataExamList = arr
      this.queryReferenceExamList = referenceArr
      this.loading = false
    },
    queryInfo() {
      if (!this.examDate) {
        return this.$message.error('至少选择一项检查日期')
      }
      if (!this.examType) {
        return this.$message.error('至少选择一项检查类型')
      }
      this.loading = true
      this.$api
        .get(`/v1/webconsole/examination/compare_examine/${this.patientIndex}`, {
          dates: this.examDate,
          examineTypeCodes: this.examType,
          filter: this.queryRules
        })
        .then(
          (res) => {
            if (res.data && res.data.data) {
              this.queryExamList = res.data.data
              this.arrayTransfer()
            }
          },
          () => {
            this.loading = false
          }
        )
    },
    getQueryInfo() {
      let p1 = new Promise((resolve, reject) => {
        this.$api
          .get(`/v1/webconsole/examination/examine_days/${this.patientIndex}`)
          .then((res) => {
            this.queryExamDateList = []
            if (res.data && res.data.data) {
              for (let i = 0; i < res.data.data.length; i++) {
                const element = res.data.data[i]
                res.data.data[i] = element.slice(0, 10)
              }
              let arr = res.data.data
              this.queryExamDate = res.data.data
              for (let i = 0; i < arr.length; i++) {
                const ele = arr[i]
                if (ele) {
                  this.queryExamDateList.push({
                    value: ele,
                    label: ele
                  })
                }
              }
            }
            resolve()
          })
          .catch(() => {
            reject('发生错误')
          })
      })
      let p2 = new Promise((resolve, reject) => {
        this.$api
          .get(`/v1/webconsole/examination/examine_types/${this.patientIndex}`)
          .then((res) => {
            this.queryExamTypeList = []
            let arr = []
            if (res.data && res.data.data) {
              for (let i in res.data.data) {
                this.queryExamType.push(i)
                arr.push({
                  label: res.data.data[i],
                  value: i
                })
              }
              for (let i = 0; i < arr.length; i++) {
                const ele = arr[i]
                this.queryExamTypeList.push({
                  value: ele.value,
                  label: ele.label
                })
              }
            }
            resolve()
          })
          .catch(() => {
            reject('发生错误')
          })
      })
      Promise.all([p1, p2]).then(() => {
        this.examDate = this.queryExamDate.join(',')
        this.examType = this.queryExamType.join(',')
        this.queryRules = this.selectRules[0]
        this.queryInfo()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.chartInfoOpen {
  width: 100%;
  .lineChart {
    width: 100%;
    height: 320px;
  }
}
.inspectionRecords-home {
  width: 100%;
  display: flex;
  flex-direction: column;
  .topHeader {
    box-shadow: 0 2px 2px #ccc;
    padding: 20px;
    .queryItem {
      margin: 10px 0;
      font-size: 18px;
      display: flex;
      align-items: center;
      .queryItemTitle {
        white-space: nowrap;
      }
    }
  }

  /deep/ .inspectionRecords-Box::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  /deep/ .inspectionRecords-Box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  /deep/ .inspectionRecords-Box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  /deep/ .inspectionRecords-Box::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .info-content {
    margin-top: 10px;
    padding: 0 20px;
    .examinationItem {
      display: flex;
      align-items: center;
      .examinationItemTitle {
        width: 160px;
      }
      .examinationItemValue {
        height: 28px;
        line-height: 28px;
      }
    }
    .el-divider__text {
      background-color: #f8f8f8;
    }
    .el-row {
      margin-bottom: 15px;
    }
    .el-input {
      width: 250px;
    }
    .el-input__inner {
      border-color: #dcdfe6;
      height: 35px;
    }
    .el-tabs__header {
      position: fixed;
      z-index: 100;
      background: #fff;
    }
    .el-tabs__content {
      .container {
        margin-top: 80px;
      }
    }
  }
  .inspectionRecords-Box {
    display: flex;
    // height: calc(90vh - 250px);
    overflow: auto;
    .inspectionRecords-table {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      /* 表格th/td样式 */
      td,
      th {
        width: 150px;
        box-sizing: border-box;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        /*超出长度...*/
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 30px;
      }

      /* 表头固定 */
      thead tr th {
        position: sticky;
        top: 0;
        background: #f4f4f5;
        height: 30px;
      }

      /* 首列固定/最后一列固定*/
      th:first-child,
      td:first-child {
        position: sticky;
        left: 0;
        background: #f4f4f5;
        text-align: center;
        right: 0px;
        border-left: 1px solid #dddddd;
        width: 100px;
      }

      /* 表头首列和最后一列强制最顶层 */
      th:last-child {
        z-index: 3;
        /*左上角单元格z-index，切记要设置，不然表格纵向横向滚动时会被该单元格右方或者下方的单元格遮挡*/
        background: #f4f4f5;
      }
    }

    .inspectionRecords-table th,
    .inspectionRecords-table td {
      white-space: nowrap;
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
    }

    .inspectionRecords-table {
      .leftTitleContent {
        .leftItemTitle {
          font-weight: bold;
          float: left;
        }
        .leftEleTitle {
          padding-left: 12px;
          cursor: pointer;
          float: right;
          .leftEleTitleItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .foldLineImg {
            width: 18px;
            height: 18px;
            padding-right: 10px;
          }
        }
      }
    }
    .inspectionRecords-content {
      flex-shrink: 0;
      text-align: center;
      // border-right: 1px #000 solid;
      border-top: 1px #000 solid;
      // border-bottom: 1px #000 solid;
      .examineDateTitle {
        width: 160px;
        height: 28px;
        line-height: 28px;
        border-bottom: 1px #000 solid;
        border-right: 1px #000 solid;
      }
      .examineSpaceContent {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px #000 solid;
      }
      .examineItem {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        border-bottom: 1px #000 solid;
        border-right: 1px #000 solid;
        cursor: default;
        .upImg {
          width: 25px;
          height: 25px;
        }
        .dnImg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
</style>
