<template>
  <div class="solution-home">
    <div class="condition-box">
      <el-card class="box-card">
        <div class="input-form-item">
          <div style="display: flex">
            <div class="input-form-item">
              <div class="input-form-item-label">检查检验日期：</div>
              <el-date-picker
                v-model="surgeryDate"
                type="daterange"
                align="center"
                size="mini"
                style="margin-right: 10px"
                unlink-panels
                ref="datepicker"
                @change="handleNextDate"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </div>
          <div>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="handleImport"
            >
              手工导入检查检验
            </el-button>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="handleExport"
            >
              导出检查检验
            </el-button>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="asyncPacs"
            >
              同步检查报告单
            </el-button>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="asyncInfo"
            >
              同步LIS
            </el-button>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="getQueryInfo2"
            >
              基线比对
            </el-button>
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-tickets"
              @click="getQueryInfo"
            >
              智能比对
            </el-button>
          </div>
        </div>
      </el-card>
    </div>
    <div class="table-content">
      <el-card style="width: 30%; height: 100%" class="box-card">
        <el-table
          class="big-table"
          :data="allList"
          :row-class-name="tableRowClassName"
          ref="leftTable"
          @row-click="rowClick"
          border
          stripe
          height="70vh"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column prop="examineType" label="检验名称"></el-table-column>
          <el-table-column prop="examineDate" label="时间"></el-table-column>
        </el-table>
      </el-card>
      <el-card style="width: 69%" class="box-card">
        <el-table height="70vh" style="width: 100%" :data="itemList" border>
          <el-table-column
            prop="itemTitle"
            align="center"
            fixed="left"
            label="项目名称"
            min-width="180"
          >
            <template slot-scope="scope">
              <div class="leftTitleContent">
                <div class="leftItemTitle">
                  {{ scope.row.itemTitle }}
                </div>
                <div class="leftEleTitle">
                  <div class="leftEleTitleItem" @click="handleExamineTitle(scope.row)">
                    <img class="foldLineImg" :src="foldLineImg" alt="" />
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="itemValue"
            align="center"
            label="检查结果值"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="itemUnit"
            align="center"
            label="单位"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="reference"
            align="center"
            label="正常值范围"
            width="150"
          ></el-table-column>
          <el-table-column prop="upDown" align="center" label="提示" width="120">
            <template slot-scope="scope">
              <div>{{ scope.row.upDown == 1 ? '↑' : scope.row.upDown == -1 ? '↓' : '' }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog width="50%" title="变化趋势" :visible.sync="examineInnerVisible">
      <el-card
        shadow="never"
        class="chartInfoOpen"
        :body-style="{ padding: '0.625rem 0.9375rem 0' }"
        v-if="examRecordTrendList?.length !== 0"
      >
        <div class="lineChart" id="lineChart"></div>
      </el-card>
    </el-dialog>
    <el-dialog
      width="30%"
      title="导入检查检验"
      class="add-pacs"
      :visible.sync="templateInnerVisible"
    >
      <div class="">
        <el-button type="text" size="default" @click="templateDownload">模板下载</el-button>
        <div class="" style="height: 52px; line-height: 52px; display: flex">
          <span>文件上传：</span>
          <el-upload ref="upload" action="" :show-file-list="false" :http-request="fileImport">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import InspectionRecords from './components/InspectionRecords'
import * as echarts from 'echarts'

import { getFormatDate } from '../../../../../utils'
export default {
  name: 'solutionInspection',
  components: {
    InspectionRecords
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.patientIndex) {
          let date = new Date()
          let start =
            val?.solutionTime ||
            this.dayjs(date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)).format('YYYY-MM-DD')
          let end = val?.solutionFinishTime || this.getFormatDate(new Date())

          this.surgeryDate = [start, end]
          this.CISReportInfo.studyStartDate = start
          this.CISReportInfo.studyEndDate = end
          this.getAllData()
        }
      },
      immediate: true
    },
    allList: function (val) {
      if (val && val.length > 0) {
        this.$nextTick(function () {
          this.rowClick(this.allList[0])
          this.$refs.leftTable.setCurrentRow(this.allList[0])
        })
      }
    }
  },
  data() {
    return {
      allList: [],
      selectedRow: [],
      itemList: [],
      surgeryDate: '',
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      CISReportInfo: {
        studyEndDate: null,
        studyStartDate: null
      },
      examineInnerVisible: false,
      examRecordTrendList: [],
      templateInnerVisible: false,
      foldLineImg: require('@/assets/img/patientCenter/foldLine.png')
    }
  },
  mounted() {},
  methods: {
    tableRowClassName({ row }) {
      if (row.defaultHighlight) {
        return 'highlight-row'
      } else {
        return ''
      }
    },
    handleImport() {
      this.templateInnerVisible = true
    },
    templateDownload() {
      this.axios
        .get(`/v1/webconsole/downloadcenter/template/examineTemplate.xls`, {
          responseType: 'blob'
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res
            const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            const contentDisposition = res.headers['content-disposition']
            let filename = '检查检验.xls'
            if (contentDisposition) {
              const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              const matches = filenameRegex.exec(contentDisposition)
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
            }
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          } else {
            this.$message({
              message: '导出失败',
              type: 'warning'
            })
          }
        })
    },
    fileImport(file) {
      let formData = new FormData()
      formData.append('file', file.file)
      this.$api.post(`/v1/webconsole/examination/import/examine/excel`, formData).then(() => {
        this.getAllData()
        this.templateInnerVisible = false
      })
    },
    handleExport() {
      this.axios
        .get(
          `/v1/webconsole/examination/patient/export/excel/1664?startDate=${this.CISReportInfo.studyStartDate}&endDate=${this.CISReportInfo.studyEndDate}`,
          {
            responseType: 'blob'
          }
        )
        .then((res) => {
          const { data, headers } = res
          const filename = headers['content-disposition'].split(';')[1].split('=')[1]
          const blob = new Blob([data], {
            type: headers['content-type']
          })
          let dom = document.createElement('a')
          let url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(filename)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
        })
    },

    // 处理时间
    getFormatDate,
    getAllData() {
      if (!this.info.patientIndex) return
      const params = new FormData()
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      params.append('menuCode', 'solution')
      params.append('businessId', this.info.id)
      this.$api
        .post(`/v1/webconsole/examination/patient/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.allList = data.data
            this.itemList = []
          }
        })
    },
    rowClick(row) {
      this.itemList = row.items || []
    },
    handleNextDate() {
      if (this.surgeryDate?.length > 0) {
        this.CISReportInfo.studyStartDate = this.surgeryDate[0]
        this.CISReportInfo.studyEndDate = this.surgeryDate[1]
      } else {
        this.CISReportInfo.studyStartDate = null
        this.CISReportInfo.studyEndDate = null
      }

      this.getAllData()
    },
    // 展示图表
    handleExamineTitle(val) {
      this.examineInnerVisible = true
      if (!val.itemTitle) {
        return this.$message.warning('该检查项不存在！')
      }

      const params = new FormData()
      params.append('itemTitle', val.itemTitle)
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      this.$api
        .post(`/v1/webconsole/examination/compare/examine/item/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.examRecordTrendList = data.data
            this.$nextTick(() => {
              this.draw_examLine()
            })
          }
        })
    },
    draw_examLine() {
      let examRecordTrendList = this.examRecordTrendList
      var lineCharts = document.getElementById('lineChart')

      //循环元素
      let xAxisData = []
      let yAxisData = []
      let title = ''
      let valueLength = null
      let decimal_point_index = 1
      examRecordTrendList.forEach((item) => {
        if (item.itemValue) {
          xAxisData.push(item.examineDate)
          yAxisData.push(item.itemValue)
          if (!title) {
            title = item.itemTitle
            valueLength = item.itemValue
          }
        }
      })
      if (valueLength > 1) {
        decimal_point_index = 1
      } else {
        decimal_point_index = 1 / 10 ** valueLength.toString().split('.').pop().length
      }
      if (this.lineChart != null && this.lineChart != '' && this.lineChart != undefined) {
        this.lineChart.dispose() // 销毁
      }
      this.lineChart = echarts.init(lineCharts) // 绘制图表
      var Option = {
        title: {
          text: title
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value',
          minInterval: decimal_point_index
        },
        series: [
          {
            data: yAxisData,
            type: 'line'
          }
        ]
      }
      this.lineChart.setOption(Option)
    },
    getQueryInfo2() {
      this.$router.push({
        path: `FormHisInspection?id=${this.info.id}&idx=${this.$route.query.idx}`
      })
    },
    getQueryInfo() {
      this.$router.push({
        path: `HisInspection?businessId=${this.info.id}`
      })
    },
    // 同步LIS
    asyncInfo() {
      if (!this.info.patientIndex) return this.$message.warning('当前患者信息不存在')
      const pdfLoading = this.$loading({
        lock: true,
        text: '同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      const params = new FormData()
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      this.$api
        .post(`/v1/webconsole/examination/lis/sync/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.$message.success('同步成功')
            this.getAllData()
          }
        })
        .catch(() => {
          this.$message.error('同步失败')
        })
        .finally(() => {
          pdfLoading.close()
        })
    },
    // 检查报告
    asyncPacs() {
      if (!this.info.patientIndex) return this.$message.warning('当前患者信息不存在')
      const pdfLoading = this.$loading({
        lock: true,
        text: '同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      const params = new FormData()
      params.append('startDate', this.CISReportInfo.studyStartDate)
      params.append('stopDate', this.CISReportInfo.studyEndDate)
      this.$api
        .post(`/v1/webconsole/examination/pacs/sync/${this.info.patientIndex}`, params)
        .then(({ data }) => {
          if (data.status === 200) {
            this.$message.success('同步成功')
            this.getAllData()
          }
        })
        .catch(() => {
          this.$message.error('同步失败')
        })
        .finally(() => {
          pdfLoading.close()
        })
    }
  }
}
</script>

<style scoped lang="less">
.add-pacs {
  /deep/.el-dialog {
    // width: 1000px !important;
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }
  }
}
.el-table /deep/ .highlight-row {
  background: #ffffcc;
}
.solution-home {
  padding: 0 10px;

  .condition-box {
    padding: 10px 0 20px 0;

    .input-form-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table-content {
    height: 75vh;
    display: flex;
    justify-content: space-between;
  }
  .big-table {
    /deep/tbody tr:hover > td {
      cursor: pointer;
    }
  }

  .leftTitleContent {
    .leftItemTitle {
      font-weight: bold;
      float: left;
    }
    .leftEleTitle {
      padding-left: 12px;
      cursor: pointer;
      float: right;
      .leftEleTitleItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .foldLineImg {
        width: 18px;
        height: 18px;
        padding-right: 10px;
      }
    }
  }

  .chartInfoOpen {
    width: 100%;
    .lineChart {
      width: 100%;
      height: 320px;
    }
  }
}
</style>
